const navigate = [
  {
    path: '/about/',
    label: 'About',
  },
  {
    path: '/blog/',
    label: 'Blog',
  },
  {
    path: '/resume/',
    label: 'Résumé',
  },
  {
    path: '/contact/',
    label: 'Contact',
  },
]

export default navigate
