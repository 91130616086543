module.exports = [{
      plugin: require('/home/travis/build/pulpfree/rondyck/node_modules/gatsby-plugin-glamor/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/pulpfree/rondyck/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"light":"#FC0008","main":"#DD0006","dark":"#BE0305"},"secondary":{"light":"#E5E5EA","main":"#8E8E93","dark":"#666666"}},"contrastThreshold":3,"tonalOffset":0.2,"typography":{"fontFamily":"Roboto","useNextVariants":true}}},
    },{
      plugin: require('/home/travis/build/pulpfree/rondyck/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/home/travis/build/pulpfree/rondyck/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/home/travis/build/pulpfree/rondyck/src/layouts/index.js"},
    },{
      plugin: require('/home/travis/build/pulpfree/rondyck/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-112253318-1","cookieDomain":"rondyck.com"},
    },{
      plugin: require('/home/travis/build/pulpfree/rondyck/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
